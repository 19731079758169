



































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import UserInfo from '@/interfaces/userInfo';

// Subsections
import VSettings from './VSettings.vue';
import VSubscription from './VSubscription.vue';
import VAddUsers from './VAddUsers.vue';
import SubscriptionInfo from '@/interfaces/subscriptionInfo';

@Component({
  components: { VSettings, VSubscription, VAddUsers },
})
export default class Account extends Vue {

  @Getter('userInfo', { namespace: 'account' }) private userInfo!: UserInfo;
  @Getter('subscriptionInfo', { namespace: 'account' }) private subscriptionInfo!: SubscriptionInfo;

}
